import React from 'react'
import { Link } from 'gatsby'
import axios from 'axios'
import _ from 'lodash'
import Layout from '../components/layout'

class StatusPage extends React.Component {
  state = {
    status: 'asdf'
  }

  componentDidMount() {
      axios.get('https://us-central1-first-street-1540149211848.cloudfunctions.net/status').then((status) => {
          this.setState({status: status.data})
         // return _.forOwn(stat.data, (value, key) => {
         //     statuas
         //})
      })
 }

  render() {
      return (
          <Layout>
            <h1> Live Status </h1>
            <hr />
            {
                _.map(this.state.status, (value, key) => (
                    <>
                    <h5> Bot: {key} </h5>
                    {_.map(value, (v, k) => (
                        <h5> {k}: {v} </h5>
                    ))}
                    <hr />
                    </>
                ))
            }
          </Layout>
      )
  }
}

export default StatusPage
